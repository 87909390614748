<template>
    <div class="loading" v-if="LoadingStatus">
        <b-container>
            <div class="loading-pi">
                <img
                    src="~@/assets/images/logo/logo.png"
                    alt=""
                    class="logo-b"
                />
            </div>
        </b-container>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            isLoading: true,
            fullPage: true,
        };
    },
    computed: {
        ...mapGetters({
            LoadingStatus: 'LoadingStatus',
        }),
        ...mapState({
            totalSend: 'totalSend',
            totalReceive: 'totalReceive',
        }),
    },
};
</script>

<style lang="scss" scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background: rgba($color: #000000, $alpha: 0.7);
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 100%;
        .loading-pi {
            width: 175px;
            height: 175px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle-bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                animation: circleRotate 4s linear infinite;
                z-index: 0;
            }
            .logo-b {
                height: 80%;
                width: auto;
                position: relative;
                animation: hover 2s linear infinite alternate;
            }
        }
    }
    @keyframes hover {
        100% {
            transform: translateY(-30px);
        }
    }
}
</style>
