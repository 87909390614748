<script>
import Loader from '@/components/shared/Loader.vue';
import Footer from './Footer.vue';
import Header from './MyHeader.vue';

export default {
    components: { Footer, Header, Loader },
    data() {
        return {
            formContact: {
                fullname: '',
                email: '',
                type: 1,
                phone: '',
                description: '',
            },
        };
    },
    mounted() {
        // this.$bvModal.show('modal-1');
    },
    methods: {
        onSendContact() {
            this.$store.dispatch(
                'contact/req_postSendContract',
                this.formContact,
            );
        },
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'core/SUCCESS_MESSAGE':
                    this.$bvModal.hide('modal-1');
                    this.formContact = {
                        fullname: '',
                        email: '',
                        type: 1,
                        phone: '',
                        description: '',
                    };
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <div fluid class="my-app">
        <Header />
        <Loader />
        <main>
            <router-view></router-view>
        </main>
        <Footer />
        <b-modal
            id="modal-1"
            title="BootstrapVue"
            centered
            hide-footer
            hide-header
        >
            <div class="close" @click="$bvModal.hide('modal-1')">X</div>
            <h1>{{ $t('consultation') }}</h1>
            <b-form class="form-contact" @submit.prevent="onSendContact">
                <b-input
                    type="text"
                    :placeholder="$t('name')"
                    required
                    v-model="formContact.fullname"
                ></b-input>
                <b-input
                    type="email"
                    placeholder="Email"
                    required
                    v-model="formContact.email"
                ></b-input>
                <b-form-group>
                    <b-form-radio
                        required
                        v-model="formContact.type"
                        name="some-radios"
                        :value="1"
                    >
                        {{ $t('investors') }}</b-form-radio
                    >
                    <b-form-radio
                        required
                        v-model="formContact.type"
                        name="some-radios"
                        :value="2"
                    >
                        {{ $t('personalBusiness') }}</b-form-radio
                    >
                </b-form-group>
                <b-input
                    type="phone"
                    :placeholder="$t('phone')"
                    required
                    v-model="formContact.phone"
                ></b-input>
                <b-form-textarea
                    id="textarea-rows"
                    rows="5"
                    :placeholder="$t('describe')"
                    v-model="formContact.description"
                ></b-form-textarea>
                <div class="custom-btn">
                    <b-button type="submit"> {{ $t('send') }}</b-button>
                </div>
            </b-form>
        </b-modal>
        <div class="contact-show" v-if="false">
            <p>
                <a href="tel:"> HOTLINE: Update Soon</a>
            </p>
            <p @click="$bvModal.show('modal-1')">{{ $t('consultation') }}</p>
        </div>
    </div>
</template>
<style lang="scss">
#modal-1 {
    .modal-content {
        border: #363795;
        border-radius: 10px;
        .modal-body {
            border-radius: 10px;
            background: none;
            background-color: #fff;
            .close {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #000093;
                color: #fff;
                font-size: 16px;
            }
            h1 {
                color: #363795;
                text-align: center;
                font-size: 30px;
                text-transform: uppercase;
            }
            .form-contact {
                width: 100%;
                max-width: 350px;
                margin: 0 auto;
                @media (max-width: 991px) {
                    max-width: inherit;
                    float: none;
                    margin-top: 30px;
                }
                .form-control {
                    width: 100%;
                    border-radius: 25px;
                    margin-bottom: 5px;
                    box-shadow: 1px 1px 5px #00000090;
                    border: none;
                    border: 1px solid #000093;
                    background: none;
                    font-weight: normal;
                    color: #000;
                    &::placeholder {
                        padding-left: 20px;
                        font-style: italic;
                        font-size: 14px;
                        font-weight: 500;
                        color: #333;
                        text-align: left;
                    }
                    &:focus-within {
                        color: #000 !important;
                    }
                }
                textarea {
                    margin: 15px auto;
                    width: 100%;
                    border-radius: 15px !important;
                    border: 1px solid #000093;
                    &::placeholder {
                        padding-left: 20px;
                        font-style: italic;
                        font-size: 14px;
                        font-weight: 500;
                        color: #33333399 !important;
                        text-align: left;
                    }
                }
                .form-group {
                    margin: 15px auto;
                    width: 90%;
                    color: #000;
                    div {
                        display: flex;
                        justify-content: space-between;
                    }
                    .bv-no-focus-ring {
                        display: flex;
                        justify-content: space-around;
                        .custom-control-inline {
                            margin-right: 0px;
                            label {
                                font-weight: bold;
                                &::before {
                                    width: 15px !important;
                                    height: 15px !important;
                                    border-radius: 50% !important;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid #000093;
                                    padding: 2px;
                                }
                                &::after {
                                    top: 0.35rem;
                                    left: -1.4rem;
                                    background: none;
                                }
                            }
                        }
                    }
                }
                .custom-btn {
                    width: 100%;
                    text-align: center;
                    button {
                        background-color: #000093;
                        border-radius: 20px;
                        width: 100%;
                        max-width: 150px;
                        font-weight: bold;
                        margin: 10px auto 0px;
                    }
                }
            }
        }
    }
}
.my-app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: space-between;
    position: relative;
    main {
        display: flex;
        width: 100%;
    }
    .contact-show {
        width: 220px;
        position: fixed;
        bottom: 10%;
        right: 10px;
        text-align: center;
        color: yellow;
        z-index: 1030 !important;
        p {
            cursor: pointer;
            background-color: #0086ff;
            padding: 5px;
            border-radius: 10px;
            font-weight: bold;
            text-transform: uppercase;
            &:hover {
                text-decoration: underline;
            }
            a {
                color: yellow !important;
            }
        }
    }
}
</style>
